import React from "react";
import Dialog from "@components/PresentationComponents/Dialog/Dialog";
import DialogContent from "@components/PresentationComponents/Dialog/DialogContent";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import DialogActions from "@components/PresentationComponents/Dialog/DialogActions";
import DialogButton from "@components/PresentationComponents/Dialog/DialogButton";
import { formatPhoneNumber } from "@helpers/functions/phoneNumberFormatter";
import { domIds } from "@constants/testConstants";
import uiString from "@constants/uiString";

const TITLE1 = "Request Toll-Free Text Messaging";
const TITLE2 = "IMPORTANT!!!"
const CONTENT1 = (number: string) => `Activate Text Messaging for ${number}`;
const CONTENT2A = "Carrier regulations have mandated that all toll-free phone numbers must be verified before text messages can be sent or received. These new regulations impact all service providers including Talkroute.";
const CONTENT2B = "This process typically takes 1-3 business days to complete.";
const CONTENT2C = "Next we will provide you with steps to verify your toll-free phone number.";
const TITLE3 = "Toll-Free Verification Process";
const STEP1 = "Step #1: Follow the ";
const STEP2 = "Step #2: Talkroute will submit your verification to the carrier(s)";
const STEP3 = "Step #3: You will be notified by email when your verification is approved";
const AGREE1 = "I understand that once verification is submitted it typically takes 1-3 business days for approval but this may take longer if my submission requires additional clarification or is denied.";
const AGREE2 = "I understand that Talkroute does not approve or deny my verification request and the decision is solely at the discretion of the carrier(s).";
const AGREE3 = "I understand that I will not be able to send or receive text messages with my toll-free phone number until the verification is approved.";
const TITLE4 = "Toll-Free Text Messaging";
const HEADER4 = (number: string) => `Your Toll-Free Activation for ${number} is now pending`;
const CONTENT4A = "Don’t forget to submit your verification request as soon as possible by following the ";
const CONTENT4B = "If you have already submitted your verification request be sure to monitor your email for further updates.";
const CONTENT4C = "Have additional questions? Feel free to contact support by emailing support@talkroute.com or by calling 1 (800) 747-2140.";

const styles = (theme: Theme) => createStyles({
    header: {
      padding: theme.spacing(2, 0),
    },
    content: {
      padding: theme.spacing(1, 0),
    },
    cancelButton: {
      border: "1px solid var(--color-dark-grey)",
      "& .MuiButton-label": {
        textTransform: "capitalize"
      }
    },
    root: {
      "& .MuiDialogTitle-root h2": {
        fontSize: "1.5rem",
        fontWeight: 400
      },
      "& .MuiTypography-body1": {
        color: "#6e6e6e",
      }
    },
    termsLabel: {
      fontSize: "1rem",
      padding: "1em 0 0",
    },
    agreeButton: {
      padding: "6px 8px"
    },
    bold: {
        fontWeight: "bold"
    },
    red: {
        color: "red !important",
        "&$red": {
            color: "red !important",
        },
    },
  });

interface IProps extends WithStyles<typeof styles> {
  open: boolean;
  phoneNumber?: string;
  onClose: () => void;
  onSubmit: () => void;
  initialStep?: number;
};

class TollFreeActivationDialog extends React.Component<IProps, any> {

    static getDerivedStateFromProps(nextProps, prevState) {
        // If the dialog is opening and an initial step is provided
        if (nextProps.open && !prevState.prevOpen && nextProps.initialStep !== undefined) {
            return {
                slideNum: nextProps.initialStep,
                prevOpen: nextProps.open, // Track the previous open state
            };
        }
        // If the dialog is closing, reset prevOpen
        if (!nextProps.open && prevState.prevOpen) {
            return {
                prevOpen: nextProps.open,
            };
        }
        return null;
    }

    setIsAgreedTerms = (value) => {
        this.setState({ isAgreedTerms: value });
    }

    setIsAgreedTimeline = (value) => {
        this.setState({ isAgreedTimeline: value });
    }

    setIsAgreedCarrier = (value) => {
        this.setState({ isAgreedCarrier: value });
    }

    setIsAgreedRestriction = (value) => {
        this.setState({ isAgreedRestriction: value });
    }

    handleConfirmClick = () => {
        this.setState((prevState) => ({ 
            slideNum: prevState.slideNum + 1 
        }), () => {
            if (this.props.onSubmit) {
                this.props.onSubmit();
            }
        });
    }

    showDialog1 = () => {
        const { open, onClose, phoneNumber, classes } = this.props;
        const { slideNum } = this.state;

        return (
            <Dialog open={open} title={TITLE1} onClose={onClose} className={classes.root}>
                <DialogContent>
                    <Typography className={classes.header} variant="h6">
                        {CONTENT1(
                            phoneNumber ? formatPhoneNumber(phoneNumber.replace("+", "")) : ""
                        )}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelButton} onClick={onClose}>
                    {uiString.updateStrings.CANCEL}
                    </Button>
                    <DialogButton
                        className={classes.agreeButton}
                        color="secondary"
                        onClick={() => this.setState({ slideNum: slideNum + 1 })}
                        label="Activate Text Messaging"
                    />
                </DialogActions>
            </Dialog>
        );
    };

    showDialog2 = () => {
        const { open, onClose, classes } = this.props;
        const { slideNum } = this.state;

        return (
            <Dialog open={open} title={TITLE2} onClose={onClose} className={[classes.root, classes.red].join(' ')}>
                <DialogContent>
                    <Typography className={[classes.content, classes.bold].join(' ')}>{CONTENT2A}</Typography>
                    <Typography className={[classes.content, classes.bold].join(' ')}>{CONTENT2B}</Typography>
                    <Typography className={[classes.content, classes.bold].join(' ')}>{CONTENT2C}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelButton} onClick={onClose}>
                        {"I do not wish to proceed"}
                    </Button>
                    <DialogButton
                        className={classes.agreeButton}
                        color="secondary"
                        onClick={() => this.setState({ slideNum: slideNum + 1 })}
                        label="I wish to proceed and verify my number"
                    />
                </DialogActions>
            </Dialog>
        );
    };

    showDialog3 = () => {
        const { open, onClose, classes } = this.props;
        const { slideNum, isAgreedCarrier, isAgreedTerms, isAgreedRestriction, isAgreedTimeline } = this.state;

         return (
         <Dialog open={open} title={TITLE3} onClose={onClose} className={classes.root}>
            <DialogContent>
                <Typography className={[classes.content, classes.bold].join(' ')}>
                    {STEP1}
                    <Link
                        href="https://talkroute.info/textver"
                        target="_blank"
                    >
                        {"instructions in this tutorial."}
                    </Link>
                </Typography>
                <Typography className={[classes.content, classes.bold].join(' ')}>{STEP2}</Typography>
                <Typography className={[classes.content, classes.bold].join(' ')}>{STEP3}</Typography>
                <FormControlLabel
                    className={classes.termsLabel}
                    label={
                        <>
                        <span style={{ paddingLeft: "1px" }}>
                            {AGREE1}
                        </span>
                    </>
                    }
                    control={
                        <Checkbox
                            id={domIds.MODAL_TERMS_CHECKBOX_MESSAGING}
                            checked={isAgreedTimeline}
                            onClick={() => this.setIsAgreedTimeline(!isAgreedTimeline)}
                            color="primary"
                        />
                    }
                />
                <FormControlLabel
                    className={classes.termsLabel}
                    label={
                        <>
                        <span style={{ paddingLeft: "1px" }}>
                            {AGREE2}
                        </span>
                    </>
                    }
                    control={
                        <Checkbox
                            id={domIds.MODAL_TERMS_CHECKBOX_MESSAGING}
                            checked={isAgreedCarrier}
                            onClick={() => this.setIsAgreedCarrier(!isAgreedCarrier)}
                            color="primary"
                        />
                    }
                />
                <FormControlLabel
                    className={classes.termsLabel}
                    label={
                        <>
                        <span style={{ paddingLeft: "1px" }}>
                            {AGREE3}
                        </span>
                    </>
                    }
                    control={
                        <Checkbox
                            id={domIds.MODAL_TERMS_CHECKBOX_MESSAGING}
                            checked={isAgreedRestriction}
                            onClick={() => this.setIsAgreedRestriction(!isAgreedRestriction)}
                            color="primary"
                        />
                    }
                />
                <FormControlLabel
                    className={classes.termsLabel}
                    label={
                        <>
                        <span style={{ paddingLeft: "1px" }}>
                            {"I agree to the Talkroute's "}
                        </span>
                        <Link
                            href="https://talkroute.com/terms-and-conditions/"
                            target="_blank"
                        >
                            {uiString.initialModal.termsModal.TITLE}
                        </Link>
                        </>
                    }
                    control={
                        <Checkbox
                            id={domIds.MODAL_TERMS_CHECKBOX_MESSAGING}
                            checked={isAgreedTerms}
                            onClick={() => this.setIsAgreedTerms(!isAgreedTerms)}
                            color="primary"
                        />
                    }
                />
            </DialogContent>
            <DialogActions>
                <Button className={classes.cancelButton} onClick={onClose}>
                    {"Cancel"}
                </Button>
                <DialogButton
                    className={classes.agreeButton}
                    color="secondary"
                    onClick={this.handleConfirmClick}
                    label="Confirm"
                    disabled={!isAgreedTerms || !isAgreedCarrier || !isAgreedRestriction || !isAgreedTimeline}
                />
            </DialogActions>
        </Dialog>
        );
    };

    showDialog4 = () => {
        const { open, onClose, phoneNumber, classes } = this.props;

        return (
            <Dialog open={open} title={TITLE4} onClose={onClose} className={classes.root}>
              <DialogContent>
                <Typography className={classes.header} variant="h5">
                  {HEADER4(
                    phoneNumber ? formatPhoneNumber(phoneNumber.replace("+", "")) : ""
                  )}
                </Typography>
                <Typography className={classes.content}>
                    {CONTENT4A}
                    <Link
                        href="https://talkroute.info/textver"
                        target="_blank"
                    >
                        {"instructions in this tutorial."}
                    </Link>
                </Typography>
                <Typography className={classes.content}>{CONTENT4B}</Typography>
                <Typography className={[classes.content, classes.bold].join(' ')}>{CONTENT4C}</Typography>
              </DialogContent>
              <DialogActions>
                <Button className={classes.cancelButton} onClick={onClose}>
                  {"Close"}
                </Button>
              </DialogActions>
            </Dialog>
          );
    };

    resetDialog = () => {
        this.setState({
            slideNum: 0,
            isAgreedTerms: false,
            isAgreedRestriction: false,
            isAgreedCarrier: false,
            isAgreedTimeline: false,
        });
    }

    showContent = () => {
        const { slideNum } = this.state;
    
        switch (slideNum) {
          case 0:
            return this.showDialog1();
          case 1:
            return this.showDialog2();
          case 2:
            return this.showDialog3();
          case 3:
            return this.showDialog4();
          default:
            return this.showDialog1();
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            slideNum: props.initialStep || 0,
            isAgreedTerms: false,
            isAgreedTimeline: false,
            isAgreedCarrier: false,
            isAgreedRestriction: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open && !this.props.open) {
            this.resetDialog();
        } else if (!prevProps.open && this.props.open) {
            if ((this.props.initialStep ?? 0) > 0) {
                this.setState({ slideNum: this.props.initialStep });
            } else {
                this.setState({
                    isAgreedTerms: false,
                    isAgreedRestriction: false,
                    isAgreedCarrier: false,
                    isAgreedTimeline: false,
                    slideNum: 0,
                });
            }
        }
    }
    

    render() {
        const { open } = this.props;
        return open ? this.showContent() : null;
    }
};

export default withStyles(styles)(TollFreeActivationDialog);